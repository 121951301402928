import React, { useRef } from 'react';
import useTheme from '../../hooks/useTheme';
import './Hero.scss';
import logo from '../../assets/rhm-logo-white.svg';

function Hero({ heading, theme }) {
    const ref = useRef(null)

    useTheme( ref, theme );

    return (
        <div className="Hero" ref={ref}>
            <div className="Hero__inner">
                <div className="Hero__body">
                    <img className="Hero__brand" src={logo} alt=""/>
                    <h1 className="Hero__heading">{heading}</h1>
                </div>
            </div>
        </div>
    );
}

export default Hero;