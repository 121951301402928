import React from 'react';
import Img from 'gatsby-image';
import './BrandGrid.scss';

function BrandGrid({caption, brands}) {
    return (
        <div className="BrandGrid">
            <p className="BrandGrid__caption">{caption}</p>
            <ul className="BrandGrid__list">
                {brands.map(({ brand }, index) => (
                    <li className="BrandGrid__item" key={index}>
                        <Img 
                            className="BrandGrid__img"
                            fluid={brand.fluid} 
                            imgStyle={{
                                objectFit: "contain",
                                objectPosition: "50% 50%",
                            }}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BrandGrid;