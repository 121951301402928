import * as React from "react"
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Hero from "../components/Hero/Hero";
import Section, { SectionBody, SectionIntro } from "../components/Section/Section";
import HtmlContent from "../components/HtmlContent";
import BrandGrid from "../components/BrandGrid/BrandGrid";
import Services from "../components/Services/Services";

const IndexPage = ({ data: { page } }) => {
  return (
    <Layout theme="primary" hideLogo={true} title={page.data.meta_title} description={page.data.meta_description}>
      <Hero heading={page.data.heading.text} theme="primary" />

      <Section theme="secondary">
        <SectionIntro heading={page.data.heading1.text}/>
        <SectionBody isColumned={true}>
          <HtmlContent content={page.data.body.html}/>
        </SectionBody>
      </Section>

      <Section theme="primary">
        <SectionIntro heading={page.data.heading2.text} lead={page.data.lead.text}/>
        <SectionBody>
          <BrandGrid caption={page.data.caption.text} brands={page.data.brands}/>
        </SectionBody>
      </Section>

      <Section theme="secondary">
        <SectionIntro heading={page.data.heading3.text} lead={page.data.lead1.text}/>
        <SectionBody>
          <Services services={page.data.services}/>
        </SectionBody>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    page: prismicHomepage {
      data {
        meta_title
        meta_description
        heading {
          text
        }
        heading1 {
          text
        }
        body {
          html
        }
        heading2 {
          text
        }
        lead {
          text
        }
        caption {
          text
        }
        brands {
          brand {
            url
            fluid(maxWidth: 158, maxHeight: 62) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        heading3 {
          text
        }
        lead1 {
          text
        }
        services {
          title {
            text
          }
          description {
            text
          }
        }
      }
    }
  }
`

export default IndexPage
