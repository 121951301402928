import React from 'react';
import './Services.scss';

function Services({services}) {
    return (
        <ul className="Services">
            {services.map(({ title, description }, index) => (
                <li className="Services__item" key={index}>
                    <h3 className="Services__title">{title.text}</h3>
                    <p className="Services__desc">{description.text}</p>
                </li>
            ))}
        </ul>
    );
}

export default Services;